<template>
    <div>
        <div ref="offcanvas" class="offcanvas" :class="{ 'offcanvas-start': position === 'left', 'offcanvas-end': position === 'right' }" :style="{ 'background-color': backgroundColor }" data-bs-scroll="true" data-bs-backdrop="true" tabindex="-1" :aria-labelledby="!!$slots.label ? labelID : null">
            <div class="offcanvas-header">
                <h2 v-if="!!$slots.label" class="offcanvas-title" :id="labelID"><slot name="label" /></h2>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div class="offcanvas-body">
                <slot name="body" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Offcanvas } from 'bootstrap';

@Component
export default class PCOffCanvas extends Vue {
    @Prop({ default: false })
    readonly value: boolean;

    @Prop({ default: '#FFF' })
    readonly backgroundColor: string;

    @Prop({ default: 'right' })
    readonly position: string;

    offCanvas: Offcanvas;

    labelID = '';

    created() {
        this.labelID = 'pc-offcanvas-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5);

        Vue.nextTick(() => {
            const elm = this.$refs.offcanvas as HTMLDivElement;
            this.offCanvas = new Offcanvas(elm);

            elm.addEventListener('hide.bs.offcanvas', e => {
                this.$emit('input', false);
            });
            elm.addEventListener('hidden.bs.offcanvas', e => {
                this.$emit('disabled');
            });
        });
    }

    @Watch('value')
    onValueChange(val: boolean) {
        if (val) {
            this.offCanvas.show();
        } else {
            this.offCanvas.hide();
        }
    }
}
</script>
