import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import SecurityRoutes from '@/views/security/routes';
import OrderListRoutes from '@/views/order-list/routes';
import OrderViewRoutes from '@/views/order-view/routes';
import RelationViewRoutes from '@/views/relation-view/routes';
import CatalogRoutes from '@/views/catalog/routes';
import CartRoutes from '@/views/cart/routes';
import AddressListRoutes from '@/views/address-list/routes';
import ReaderListRoutes from '@/views/reader-list/routes';
import ContactListRoutes from '@/views/contact-list/routes';
import CirculationListRoutes from '@/views/circulation-list/routes';
import PackingSlipListRoutes from '@/views/packing-slip-list/routes';
import InvoiceListRoutes from '@/views/invoice-list/routes';
import ManagementInfoRoutes from '@/views/management-info/routes';
import BudgetingRoutes from '@/views/budgeting/routes';
import ContactRoutes from '@/views/contact/routes';
import NewsListRoutes from '@/views/news-list/routes';
import MySettingsRoutes from '@/views/my-settings/routes';

Vue.use(VueRouter);

const HomeRoutes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/index.vue'),
    meta: {
      title: 'My products'
    }
  },
]

const routes = [
    ...HomeRoutes,
    ...SecurityRoutes,
    ...OrderListRoutes,
    ...OrderViewRoutes,
    ...RelationViewRoutes,
    ...CatalogRoutes,
    ...CartRoutes,
    ...AddressListRoutes,
    ...ReaderListRoutes,
    ...ContactListRoutes,
    ...CirculationListRoutes,
    ...PackingSlipListRoutes,
    ...InvoiceListRoutes,
    ...ManagementInfoRoutes,
    ...BudgetingRoutes,
    ...ContactRoutes,
    ...NewsListRoutes,
    ...MySettingsRoutes,
];

const router = new VueRouter({
  mode: 'hash', // Todo: 'history' will fix WCAG anchor link issues, but cause new issues. Also requires server config: https://v3.router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
  routes: routes,
});

export default router;
