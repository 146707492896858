<template>
  <div class="form-check">
      <input class="form-check-input" type="checkbox" v-bind="$attrs" v-model="checked" :id="labelID" @change="$emit('change', checked)">
      <label class="form-check-label" :class="{ 'd-none': hideLabel }" :for="labelID"> {{ $t(label) }}</label>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';

@Component({
  inheritAttrs: false,
})
export default class PCCheckBox extends Vue {
    @Prop({ default: '' })
    readonly label: string;

    @Prop({ default: false })
    readonly hideLabel: boolean;

    @Prop({ default: false })
    readonly value: boolean|Array<string>;

    @Prop({ default: '' })
    readonly itemValue: string;

    checked = false;
    labelID = '';

    mounted () {
        this.onValueChange(this.value);
        this.labelID = 'pc-check-' + Math.random().toString(36).replace(/[^a-z]+/g, '').substring(0, 5)
    }

    @Watch('checked')
    onCheckedChange(val: boolean) {
        if (Array.isArray(this.value)) {
            let output = [...this.value];

            if (val) {
                if (output.indexOf(this.itemValue) === -1) {
                    output.push(this.itemValue);
                }
            } else {
                output = output.filter((a) => {
                    return a !== this.itemValue;
                });
            }
            this.$emit('input', output);
            this.$emit('change', output);
        } else {
            this.$emit('input', val);
            this.$emit('change', val);
        }
    }

    @Watch('value')
    onValueChange(val: boolean|Array<string>) {
        if (Array.isArray(val)) {
            this.checked = val.indexOf(this.itemValue) > -1;
        } else {
            this.checked = val;
        }
    }
}
</script>
